export const gateway = "https://western-api.link.wawanesalife.com";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"western.link.wawanesalife.com": {
		a: {
			broker: "western",
			accessibilityLink: "https://westernfinancialgroup.ca/Accessibility-Guidelines",
			themeSwitcher: "hide",
			privacyLink: "https://westernfinancialgroup.ca/Privacy",
			scope: "western#branding#western.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "myinsurancenow@westernfg.ca",
			video_link: "dOPJxsVASJA",
			map2: "hide",
			environment_id: "stable",
			map1: "hide",
			brands: "western.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "dOPJxsVASJA",
			phone: "(888) 594-3105",
			menuPhone: "button",
			environment: "stable",
			website: "https://westernfinancialgroup.ca/",
			ftcLink: "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
			layout: "flat",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_western",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "western.link.wawanesalife.com",
		},
		b: {
			broker: "western",
			accessibilityLink: "https://westernfinancialgroup.ca/Accessibility-Guidelines",
			themeSwitcher: "hide",
			privacyLink: "https://westernfinancialgroup.ca/Privacy",
			scope: "western#branding#western.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "myinsurancenow@westernfg.ca",
			video_link: "dOPJxsVASJA",
			map2: "hide",
			environment_id: "stable",
			map1: "hide",
			brands: "western.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "dOPJxsVASJA",
			phone: "(888) 594-3105",
			menuPhone: "button",
			environment: "stable",
			website: "https://westernfinancialgroup.ca/",
			ftcLink: "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
			layout: "flat",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_western",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "western.link.wawanesalife.com",
		},
	},
};

//updated on Sun May 19 2024 12:32:59 GMT-0700 (Pacific Daylight Time)
