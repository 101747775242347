import 'bootstrap';

import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { LinkShopper } from './app';

let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);
let isDesktop = !(window.matchMedia("(max-width: 480px)").matches);
//onsole.info("Starting up");

ReactDOM.createRoot(document.getElementById("app")).render(
    <LinkShopper isDesktop={isDesktop} query={query} />
);
