
export const headings = {
    diagnosis: "Have you ever been treated for, diagnosed, consulted a doctor, received abnormal test results or experienced symptoms of the following:",
    last2: "In the past 2 years, have you been advised of an abnormal result of any of the following?",
    last5: "In the past 5 years, have you:",
    last10: "In the past 10 years, have you:",
    medical: "In the past 2 years, have you:",
    occupation: "Does your occupation belong to any of the following?",
};
export const smoking = {
    "id": "4349",
    "no": "6737",
    "yes": "6735",
    "maybe": {
        "id": "6736",
        "no": "6733",
        "yes": "6734"
    }
}

export const general = [
    {
        "id": "4350",
        "copy": "What is your weekly alcohol consumption?",
        "help": "One unit of alcohol consists of; 43ml (1.5 oz.) spirits, 142ml (5 oz.) of wine or 341ml (12 oz.) of beer",
        "type": "radio",
        "bundle": "none",
        "order": 1,
        "choices": {
            "6741": "26 or more",
            "6743": "1-25 drinks",
            "6744": "None, or less than weekly"
        },
        "paths": {
            "mismatch": ["6741"]
        }
    },
    {
        "id": "4351",
        "copy": "What is your weekly marijuana consumption?",
        "help": "Please indicate how often you consume marijuana or other cannabis products",
        "type": "radio",
        "bundle": "none",
        "order": 2,
        "choices": {
            "6746": "4 or more",
            "6747": "3 or less",
            "6748": "None, or less than weekly"
        },
        "paths": {
            "mismatch": ["6746"]
        }
    }
];

export const introduction = [
    {
        "id": "4297",
        "copy": "Is this policy intended to replace any life insurance currently in effect?",
        "stated": "this policy is not intended to replace any life insurance currently in effect",
        "type": "radio",
        "bundle": "statement",
        "choices": {
            "6641": {
                "id": "6641",
                "copy": "Yes",
                "path": "mismatch"
            },
            "6642": {
                "id": "6642",
                "copy": "No",
                "path": "application"
            }
        },
        "paths": {
            "mismatch": ["6641"]
        }
    },
    {
        "id": "4298",
        "copy": "Are you a Canadian citizen or landed immigrant, currently residing in Canada?",
        "stated": "you are a Canadian citizen or landed immigrant, currently residing in Canada",
        "type": "radio",
        "bundle": "statement",
        "choices": {
            "6643": {
                "id": "6643",
                "copy": "Yes",
                "path": "application"
            },
            "6644": {
                "id": "6644",
                "copy": "No",
                "path": "unqualified"
            }
        },
        "paths": {
            "unqualified": ["6644"]
        }
    },
    {
        "id": "4303",
        "copy": "Do you agree to answer all of these questions truthfully?",
        "stated": "you agree to answer all of these questions truthfully",
        "type": "radio",
        "bundle": "statement",
        "choices": {
            "6650": {
                "id": "6650",
                "copy": "Yes",
                "path": "application"
            },
            "6651": {
                "id": "6651",
                "copy": "No",
                "path": "unqualified"
            }
        },
        "paths": {
            "unqualified": ["6651"]
        }
    },
    // {
    //     "id": "0000",
    //     "copy": "What is the purpose of this insurance policy?",
    //     "stated": "this policy is not intended to replace any life insurance currently in effect",
    //     "type": "radio",
    //     "bundle": "statement",
    //     "choices": {
    //         "0001": {
    //             "id": "0001",
    //             "copy": "Personal",
    //             "path": "application"
    //         },
    //         "0002": {
    //             "id": "0002",
    //             "copy": "Business",
    //             "path": "application"
    //         }
    //     },
    //     "paths": {
    //         "mismatch": ["0003"]
    //     }
    // }
];


export const occupation = [

    [
        {
            "copy": "Fisherman (not coming home nightly)",
            "id": 0,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Forestry (Chipper, Choke Setter, Chopper, Faller, Bucker, Marker, Sorter, Logger, Lumberjack, Raftsman, Riverman, Woodsman)",
            "id": 1,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Journalist & Photographic journalist, Missionary (international assignments)",
            "id": 2,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Building industry (Asbestos Worker, structural iron or steel workers on towers, bridges, buildings over 3 stories)",
            "id": 3,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Professional Diving (Cableman, Deep Sea, demolition, oil rig, pipe laying, saturation)",
            "id": 4,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Carnival, Acrobat, Animal trainer, Rodeo Performer/Competitor",
            "id": 5,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        }
    ],
    [
        {
            "copy": "Offshore workers (Oil and Gas)",
            "id": 6,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Professional gambler",
            "id": 7,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Professional Athlete",
            "id": 8,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Military currently deployed or under orders to deploy",
            "id": 9,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        },
        {
            "copy": "Commercial aviation (other than pilot for major airline)",
            "id": 10,
            "parentId": "4299",
            "yes": "6645",
            "no": "6646"
        }
    ]
];

export const last10 = [
    {
        "id": "4314",
        "copy": "Had an application for individual life insurance or critical illness insurance rated, declined, postponed or had exclusions added by Wawanesa Life or any other company?",
        "yes": "6670",
        "no": "6671",
        "paths": {
            "mismatch": ["Yes"]
        }
    },
    {
        "id": "4315",
        "copy": "Been accused of, or convicted of, a criminal offense, or are you currently on probation?",
        "yes": "6672",
        "no": "6673",
        "paths": {
            "unqualified": ["Yes"]
        }
    }
];

export const last5 = [
    {
        "id": "4317",
        "copy": "Been disabled or admitted to any hospital or rehabilitation center for more than 2 consecutive weeks?",
        "yes": "6675",
        "no": "6676",
        "paths": {
            "mismatch": ["Yes"]
        }
    },
    {
        "id": "4318",
        "copy": "Used heroin, cocaine, hallucinogens, or any drug not prescribed to you by a physician, other than marijuana or over-the-counter medication?",
        "yes": "6677",
        "no": "6678",
        "paths": {
            "mismatch": ["Yes"]
        }
    },
    {
        "id": "4319",
        "copy": "Received counselling or treatment or been advised to seek treatment or medical advice because of your alcohol or drug use?",
        "yes": "6679",
        "no": "6680",
        "paths": {
            "mismatch": ["Yes"]
        }
    }
];


export const last2 = [
    {
        "id": "4321",
        "copy": "Colonoscopy",
        "yes": "6682",
        "no": "6683",
        "drilldown": [
            {
                "id": "4322",
                "copy": "Colonoscopy result",
                "type": "heading",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger"
            },
            {
                "id": "4323",
                "copy": "Hemorrhoids only",
                "path": "application",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4322",
                "choices": {
                    "6685": {
                        "id": "6685",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6686": {
                        "id": "6686",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4324",
                "copy": "IBS (Irritable Bowel Syndrome) only, controlled",
                "type": "radio",
                "path": "application",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4322",
                "choices": {
                    "6687": {
                        "id": "6687",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6688": {
                        "id": "6688",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4325",
                "copy": "Other",
                "type": "radio",
                "parent": "6682",
                "path": "queue",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4322",
                "choices": {
                    "6689": {
                        "id": "6689",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6690": {
                        "id": "6690",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4326",
                "copy": "Follow-up colonoscopy recommended?",
                "type": "heading",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger"
            },
            {
                "id": "4327",
                "copy": "No",
                "type": "radio",
                "path": "application",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4326",
                "choices": {
                    "6692": {
                        "id": "6692",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6693": {
                        "id": "6693",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4328",
                "copy": "Do not know",
                "type": "radio",
                "parent": "6682",
                "path": "queue",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4326",
                "choices": {
                    "6694": {
                        "id": "6694",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6695": {
                        "id": "6695",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4329",
                "copy": "Yes, in 5-10 years",
                "type": "radio",
                "path": "application",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4326",
                "choices": {
                    "6696": {
                        "id": "6696",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6697": {
                        "id": "6697",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4330",
                "copy": "Yes, in 3 years",
                "path": "queue",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4326",
                "choices": {
                    "6698": {
                        "id": "6698",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6699": {
                        "id": "6699",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4331",
                "copy": "Yes, within 3 years",
                "path": "queue",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "drilldownGroup": "q4326",
                "choices": {
                    "6700": {
                        "id": "6700",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6701": {
                        "id": "6701",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    {
        "id": "4332",
        "copy": "MRI or CT scan",
        "yes": "6702",
        "no": "6703"
    },
    {
        "id": "4333",
        "copy": "Mammogram or breast imaging test",
        "yes": "6704",
        "no": "6705"
    },
    {
        "id": "4334",
        "copy": "EKG and echocardiogram",
        "yes": "6706",
        "no": "6707"
    },
    {
        "id": "4335",
        "copy": "Biopsy or pathology results other than basal cell carcinoma",
        "yes": "6708",
        "no": "6709"
    }
];

export const medical = [
    {
        "id": "4337",
        "copy": "Had depression for which you were prescribed medication?",
        "yes": "6711",
        "no": "6712",
        "followUp": [
            {
                "id": "4338",
                "copy": "Depression was diagnosed:",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6713": {
                        "id": "6713",
                        "copy": "Within the last 12 months",
                        "path": "mismatch"
                    },
                    "6714": {
                        "id": "6714",
                        "copy": "More than 12 months ago",
                        "path": "application"
                    }
                }
            },
            {
                "id": "4339",
                "copy": "Are you being followed by:",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6715": {
                        "id": "6715",
                        "copy": "Specialist (psychiatrist)",
                        "path": "queue"
                    },
                    "6716": {
                        "id": "6716",
                        "copy": "Family doctor",
                        "path": "application"
                    }
                }
            },
            {
                "id": "4340",
                "copy": "Within the last 2 years, have you required any time off work because of depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6717": {
                        "id": "6717",
                        "copy": "Yes",
                        "path": "queue"
                    },
                    "6718": {
                        "id": "6718",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4341",
                "copy": "Within the last 2 years, have you been hospitalized or had emergency visit(s) because of depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6719": {
                        "id": "6719",
                        "copy": "Yes",
                        "path": "queue"
                    },
                    "6720": {
                        "id": "6720",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4342",
                "copy": "Have you ever had any suicidal thoughts or attempts?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6721": {
                        "id": "6721",
                        "copy": "Yes",
                        "path": "queue"
                    },
                    "6722": {
                        "id": "6722",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4343",
                "copy": "Within the last year, have you had an increase in the dosage of medication for depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6723": {
                        "id": "6723",
                        "copy": "Yes",
                        "path": "queue"
                    },
                    "6724": {
                        "id": "6724",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    {
        "id": "4344",
        "copy": "Received disability benefits or social assistance?",
        "yes": "6725",
        "no": "6726"
    },
    {
        "id": "4345",
        "copy": "Had your driver's license suspended?",
        "yes": "6727",
        "no": "6728"
    },
    {
        "id": "4346",
        "copy": "Been charged with driving while impaired?",
        "yes": "6729",
        "no": "6730"
    },
    {
        "id": "4347",
        "copy": "Had more than 3 driving violations?",
        "yes": "6731",
        "no": "6732"
    }
];


export const diagnosis =
    [
        {
            "id": "4310",
            "copy": "Liver or kidney failure, cirrhosis, polycystic kidney disease, chronic kidney disease, pancreatitis, or tested positive for hepatitis B, C or HIV?",
            "order": 1,
            "choices": {},
            "yes": "6663",
            "no": "6664",
            "bullets": [
                "Liver or kidney failure",
                " cirrhosis",
                " polycystic kidney disease",
                " chronic kidney disease",
                " pancreatitis",
                " or tested positive for hepatitis B, C or HIV"
            ]
        },
        {
            "id": "4307",
            "copy": "Cancer or other malignant disease, including cancerous colon polyp, leukemia, lymphoma, cancerous tumor or cancerous growth (other than basal cell carcinoma)?",
            "order": 2,
            "choices": {},
            "yes": "6657",
            "no": "6658",
            "bullets": [
                "Cancer or other malignant disease",
                " including cancerous colon polyp",
                " leukemia",
                " lymphoma",
                " cancerous tumor or cancerous growth"
            ]
        },
        {
            "id": "4308",
            "copy": "Lung or respiratory disease (other than asthma, cold, flu, or bronchitis)?",
            "order": 3,
            "choices": {},
            "yes": "6659",
            "no": "6660"
        },
        {
            "id": "4312",
            "copy": "Rheumatoid arthritis, systemic lupus, muscular dystrophy, multiple sclerosis, cerebral palsy, or amyotrophic lateral sclerosis (ALS)?",
            "order": 4,
            "choices": {},
            "yes": "6667",
            "no": "6668",
            "bullets": [
                "Rheumatoid arthritis",
                " systemic lupus",
                " muscular dystrophy",
                " multiple sclerosis",
                " cerebral palsy",
                " or amyotrophic lateral sclerosis"
            ]
        },
        {
            "id": "4311",
            "copy": "Schizophrenia, bi-polar disease, psychosis, attempted suicide or been hospitalized for any psychiatric illness?",
            "order": 5,
            "choices": {},
            "yes": "6665",
            "no": "6666",
            "bullets": [
                "Schizophrenia",
                " bi-polar disease",
                " psychosis",
                " attempted suicide or been hospitalized for any psychiatric illness"
            ]
        },
        {
            "id": "4306",
            "copy": "Heart attack, cardiac surgery, congenital cardiac defects, angina, angioplasty, coronary artery bypass, congestive heart failure, stroke, transient ischemic attack (TIA), cardiomyopathy, aneurysm, pacemaker, arteriosclerosis or any other cerebrovascular disease or disease of the heart or the blood vessels (excluding well controlled hypertension or controlled cholesterol)?",
            "order": 6,
            "choices": {},
            "yes": "6655",
            "no": "6656",
            "bullets": [
                "Heart attack",
                " cardiac surgery",
                " congenital cardiac defects",
                " angina",
                " angioplasty",
                " coronary atery bypass",
                " congestive heart failure",
                " stroke",
                " transient ischemic attack",
                " caridomyopathy",
                " aneurysm",
                " pacemaker",
                " arteriosclerosis or any other cerebrovascular disease or disease of the heart or the blood vessels"
            ]
        },
        {
            "id": "4309",
            "copy": "Type 1 (insulin-dependent) diabetes or type 2 diabetes?",
            "order": 7,
            "choices": {},
            "yes": "6661",
            "no": "6662"
        }
    ];

export const misc = [
    {
        "id": "4304",
        "copy": "Are you currently restricted to a wheelchair, bedridden, hospitalized or require full time care?",
        "yes": "6652",
        "no": "6653"
    },
    {
        "id": "4352",
        "copy": "Have you consulted a physician for an illness or condition which has not yet been diagnosed or for which testing is still in progress or not yet completed, or are you awaiting the result of any clinical test?",
        "yes": "6749",
        "no": "6750"
    },
    {
        "id": "4353",
        "copy": "Have you noticed any symptoms or health problems for which you have not yet consulted a physician, such as:",
        "yes": "6751",
        "no": "6752",
        "bullets": [
            " lump or mass of the breasts",
            " shortness of breath",
            " chest pain",
            " unexplained weight loss",
            " dizziness",
            " loss of memory or balance",
            " numbness",
            " rectal bleeding",
            " prostate or other problems not mentioned above"
        ]
    },
    {
        "id": "4354",
        "copy": "In the past 2 years, have you flown as a private pilot or student pilot, or have you engaged in:",
        "yes": "6753",
        "no": "6754",
        "bullets": [
            " SCUBA diving deeper than 100 feet",
            " sky diving",
            " hang gliding",
            " motor vehicle racing",
            " mountain climbing",
            " heli-skiing",
            " back country skiing/snowboarding/snowmobiling",
            " or extreme sports"
        ]
    },
    {
        "id": "4355",
        "copy": "Other than travel totaling 6 months or less per year within, Europe, Australia, Japan, China, Singapore or the Caribbean, do you have any plans to travel or reside outside of North America in the next 12 months?",
        "yes": "6755",
        "no": "6756"
    }
];

export const bundled = {
    "physician": {
        "type": "form",
        "heading": "",
        "questions": [
            {
                "id": "4300",
                "copy": "Full Name of your Personal Physician (if no personal physician, provide name of the doctor or clinic last attended):",
                "type": "text",
                "bundle": "physician"
            },
            {
                "id": "4301",
                "copy": "Physician's Address",
                "type": "text",
                "bundle": "physician"
            },
            {
                "id": "4302",
                "copy": "Physician's Phone Number:",
                "type": "text",
                "bundle": "physician"
            }
        ]
    },
    "diagnostic": {
        "type": "carousel",
        "heading": "",
        "questions": [
            {
                "id": "4305",
                "copy": "Have you ever been treated for, diagnosed, consulted a doctor, received abnormal test results or experienced symptoms of the following:",
                "type": "heading",
                "bundle": "diagnostic",
                "role": "heading"
            },
            {
                "id": "4306",
                "copy": "Heart attack, cardiac surgery, congenital cardiac defects, angina, angioplasty, coronary artery bypass, congestive heart failure, stroke, transient ischemic attack (TIA), cardiomyopathy, aneurysm, pacemaker, arteriosclerosis or any other cerebrovascular disease or disease of the heart or the blood vessels (excluding well controlled hypertension or controlled cholesterol)?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 6,
                "choices": {
                    "6655": {
                        "id": "6655",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6656": {
                        "id": "6656",
                        "copy": "No",
                        "path": "mismatch"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Heart attack",
                    " cardiac surgery",
                    " congenital cardiac defects",
                    " angina",
                    " angioplasty",
                    " coronary atery bypass",
                    " congestive heart failure",
                    " stroke",
                    " transient ischemic attack",
                    " caridomyopathy",
                    " aneurysm",
                    " pacemaker",
                    " arteriosclerosis or any other cerebrovascular disease or disease of the heart or the blood vessels"
                ]
            },
            {
                "id": "4307",
                "copy": "Cancer or other malignant disease, including cancerous colon polyp, leukemia, lymphoma, cancerous tumor or cancerous growth (other than basal cell carcinoma)?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 2,
                "choices": {
                    "6657": {
                        "id": "6657",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6658": {
                        "id": "6658",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Cancer or other malignant disease",
                    " including cancerous colon polyp",
                    " leukemia",
                    " lymphoma",
                    " cancerous tumor or cancerous growth"
                ]
            },
            {
                "id": "4308",
                "copy": "Lung or respiratory disease (other than asthma, cold, flu, or bronchitis)?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 3,
                "choices": {
                    "6659": {
                        "id": "6659",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6660": {
                        "id": "6660",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4309",
                "copy": "Type 1 (insulin-dependent) diabetes or type 2 diabetes?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 7,
                "choices": {
                    "6661": {
                        "id": "6661",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6662": {
                        "id": "6662",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4310",
                "copy": "Liver or kidney failure, cirrhosis, polycystic kidney disease, chronic kidney disease, pancreatitis, or tested positive for hepatitis B, C or HIV?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 1,
                "choices": {
                    "6663": {
                        "id": "6663",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6664": {
                        "id": "6664",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Liver or kidney failure",
                    " cirrhosis",
                    " polycystic kidney disease",
                    " chronic kidney disease",
                    " pancreatitis",
                    " or tested positive for hepatitis B, C or HIV"
                ]
            },
            {
                "id": "4311",
                "copy": "Schizophrenia, bi-polar disease, psychosis, attempted suicide or been hospitalized for any psychiatric illness?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 5,
                "choices": {
                    "6665": {
                        "id": "6665",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6666": {
                        "id": "6666",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Schizophrenia",
                    " bi-polar disease",
                    " psychosis",
                    " attempted suicide or been hospitalized for any psychiatric illness"
                ]
            },
            {
                "id": "4312",
                "copy": "Rheumatoid arthritis, systemic lupus, muscular dystrophy, multiple sclerosis, cerebral palsy, or amyotrophic lateral sclerosis (ALS)?",
                "type": "radio",
                "bundle": "diagnostic",
                "role": "item",
                "order": 4,
                "choices": {
                    "6667": {
                        "id": "6667",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6668": {
                        "id": "6668",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Rheumatoid arthritis",
                    " systemic lupus",
                    " muscular dystrophy",
                    " multiple sclerosis",
                    " cerebral palsy",
                    " or amyotrophic lateral sclerosis"
                ]
            }
        ]
    },
    "10years": {
        "type": "carousel",
        "heading": "",
        "questions": [
            {
                "id": "4313",
                "copy": "In the past 10 years, have you:",
                "type": "heading",
                "bundle": "10years",
                "role": "heading"
            },
            {
                "id": "4314",
                "copy": "Had an application for individual life insurance or critical illness insurance rated, declined, postponed or had exclusions added by Wawanesa Life or any other company?",
                "type": "radio",
                "bundle": "10years",
                "role": "item",
                "choices": {
                    "6670": {
                        "id": "6670",
                        "copy": "Yes",
                        "path": "unqualified"
                    },
                    "6671": {
                        "id": "6671",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4315",
                "copy": "Been accused of, or convicted of, a criminal offense, or are you currently on probation?",
                "type": "radio",
                "bundle": "10years",
                "role": "item",
                "choices": {
                    "6672": {
                        "id": "6672",
                        "copy": "Yes",
                        "path": "unqualified"
                    },
                    "6673": {
                        "id": "6673",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "5years": {
        "type": "carousel",
        "heading": "",
        "questions": [
            {
                "id": "4316",
                "copy": "In the past 5 years, have you:",
                "type": "heading",
                "bundle": "5years",
                "role": "heading"
            },
            {
                "id": "4317",
                "copy": "Been disabled or admitted to any hospital or rehabilitation center for more than 2 consecutive weeks?",
                "type": "radio",
                "bundle": "5years",
                "role": "item",
                "choices": {
                    "6675": {
                        "id": "6675",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6676": {
                        "id": "6676",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4318",
                "copy": "Used heroin, cocaine, hallucinogens, or any drug not prescribed to you by a physician, other than marijuana or over-the-counter medication?",
                "type": "radio",
                "bundle": "5years",
                "role": "item",
                "choices": {
                    "6677": {
                        "id": "6677",
                        "copy": "Yes",
                        "path": "unqualified"
                    },
                    "6678": {
                        "id": "6678",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4319",
                "copy": "Received counselling or treatment or been advised to seek treatment or medical advice because of your alcohol or drug use?",
                "type": "radio",
                "bundle": "5years",
                "role": "item",
                "choices": {
                    "6679": {
                        "id": "6679",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6680": {
                        "id": "6680",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "2years": {
        "type": "carousel",
        "useSwitches": "yes",
        "heading": "",
        "questions": [
            {
                "id": "4320",
                "copy": "In the past 2 years, have you been advised of an abnormal result of any of the following?",
                "type": "heading",
                "bundle": "2years",
                "role": "heading"
            },
            {
                "id": "4321",
                "copy": "Colonoscopy",
                "type": "radio",
                "bundle": "2years",
                "role": "item",
                "choices": {
                    "6682": {
                        "id": "6682",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6683": {
                        "id": "6683",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "drilldown": [
                    {
                        "id": "4322",
                        "copy": "Colonoscopy result",
                        "type": "heading",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger"
                    },
                    {
                        "id": "4323",
                        "copy": "Hemorrhoids only",
                        "path": "application",
                        "type": "radio",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4322",
                        "choices": {
                            "6685": {
                                "id": "6685",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6686": {
                                "id": "6686",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4324",
                        "copy": "IBS (Irritable Bowel Syndrome) only, controlled",
                        "type": "radio",
                        "path": "application",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4322",
                        "choices": {
                            "6687": {
                                "id": "6687",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6688": {
                                "id": "6688",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4325",
                        "copy": "Other",
                        "type": "radio",
                        "parent": "6682",
                        "path": "queue",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4322",
                        "choices": {
                            "6689": {
                                "id": "6689",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6690": {
                                "id": "6690",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4326",
                        "copy": "Follow-up colonoscopy recommended?",
                        "type": "heading",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger"
                    },
                    {
                        "id": "4327",
                        "copy": "No",
                        "type": "radio",
                        "path": "application",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4326",
                        "choices": {
                            "6692": {
                                "id": "6692",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6693": {
                                "id": "6693",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4328",
                        "copy": "Do not know",
                        "type": "radio",
                        "parent": "6682",
                        "path": "queue",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4326",
                        "choices": {
                            "6694": {
                                "id": "6694",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6695": {
                                "id": "6695",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4329",
                        "copy": "Yes, in 5-10 years",
                        "type": "radio",
                        "path": "application",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4326",
                        "choices": {
                            "6696": {
                                "id": "6696",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6697": {
                                "id": "6697",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4330",
                        "copy": "Yes, in 3 years",
                        "path": "queue",
                        "type": "radio",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4326",
                        "choices": {
                            "6698": {
                                "id": "6698",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6699": {
                                "id": "6699",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4331",
                        "copy": "Yes, within 3 years",
                        "path": "queue",
                        "type": "radio",
                        "parent": "6682",
                        "role": "secondary",
                        "bundle": "trigger",
                        "drilldownGroup": "q4326",
                        "choices": {
                            "6700": {
                                "id": "6700",
                                "copy": "Yes",
                                "path": "application"
                            },
                            "6701": {
                                "id": "6701",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    }
                ]
            },
            {
                "id": "4332",
                "copy": "MRI or CT scan",
                "type": "radio",
                "bundle": "2years",
                "role": "item",
                "choices": {
                    "6702": {
                        "id": "6702",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6703": {
                        "id": "6703",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4333",
                "copy": "Mammogram or breast imaging test",
                "type": "radio",
                "bundle": "2years",
                "role": "item",
                "choices": {
                    "6704": {
                        "id": "6704",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6705": {
                        "id": "6705",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4334",
                "copy": "EKG and echocardiogram",
                "type": "radio",
                "bundle": "2years",
                "role": "item",
                "choices": {
                    "6706": {
                        "id": "6706",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6707": {
                        "id": "6707",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4335",
                "copy": "Biopsy or pathology results other than basal cell carcinoma",
                "type": "radio",
                "bundle": "2years",
                "role": "item",
                "choices": {
                    "6708": {
                        "id": "6708",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6709": {
                        "id": "6709",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "mental": {
        "type": "carousel",
        "useSwitches": "no",
        "heading": "",
        "questions": [
            {
                "id": "4336",
                "copy": "In the past 2 years, have you:",
                "type": "heading",
                "bundle": "mental",
                "role": "heading"
            },
            {
                "id": "4337",
                "copy": "Had depression for which you were prescribed medication?",
                "type": "radio",
                "bundle": "mental",
                "role": "item",
                "choices": {
                    "6711": {
                        "id": "6711",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6712": {
                        "id": "6712",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "followUp": [
                    {
                        "id": "4338",
                        "copy": "Depression was diagnosed:",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6713": {
                                "id": "6713",
                                "copy": "Within the last 12 months",
                                "path": "mismatch"
                            },
                            "6714": {
                                "id": "6714",
                                "copy": "More than 12 months ago",
                                "path": "application"
                            }
                        }
                    },
                    {
                        "id": "4339",
                        "copy": "Are you being followed by:",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6715": {
                                "id": "6715",
                                "copy": "Specialist (psychiatrist)",
                                "path": "queue"
                            },
                            "6716": {
                                "id": "6716",
                                "copy": "Family doctor",
                                "path": "application"
                            }
                        }
                    },
                    {
                        "id": "4340",
                        "copy": "Within the last 2 years, have you required any time off work because of depression?",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6717": {
                                "id": "6717",
                                "copy": "Yes",
                                "path": "queue"
                            },
                            "6718": {
                                "id": "6718",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4341",
                        "copy": "Within the last 2 years, have you been hospitalized or had emergency visit(s) because of depression?",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6719": {
                                "id": "6719",
                                "copy": "Yes",
                                "path": "queue"
                            },
                            "6720": {
                                "id": "6720",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4342",
                        "copy": "Have you ever had any suicidal thoughts or attempts?",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6721": {
                                "id": "6721",
                                "copy": "Yes",
                                "path": "queue"
                            },
                            "6722": {
                                "id": "6722",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    },
                    {
                        "id": "4343",
                        "copy": "Within the last year, have you had an increase in the dosage of medication for depression?",
                        "type": "radio",
                        "parent": "6711",
                        "role": "secondary",
                        "bundle": "trigger",
                        "choices": {
                            "6723": {
                                "id": "6723",
                                "copy": "Yes",
                                "path": "queue"
                            },
                            "6724": {
                                "id": "6724",
                                "copy": "No",
                                "path": "application"
                            }
                        },
                        "isYesNo": "yes"
                    }
                ]
            },
            {
                "id": "4344",
                "copy": "Received disability benefits or social assistance?",
                "type": "radio",
                "bundle": "mental",
                "role": "item",
                "choices": {
                    "6725": {
                        "id": "6725",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6726": {
                        "id": "6726",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4345",
                "copy": "Had your driver's license suspended?",
                "type": "radio",
                "bundle": "mental",
                "role": "item",
                "choices": {
                    "6727": {
                        "id": "6727",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6728": {
                        "id": "6728",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4346",
                "copy": "Been charged with driving while impaired?",
                "type": "radio",
                "bundle": "mental",
                "role": "item",
                "choices": {
                    "6729": {
                        "id": "6729",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6730": {
                        "id": "6730",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4347",
                "copy": "Had more than 3 driving violations?",
                "type": "radio",
                "bundle": "mental",
                "role": "item",
                "choices": {
                    "6731": {
                        "id": "6731",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6732": {
                        "id": "6732",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "smoking": {
        "type": "special",
        "questions": [
            {
                "id": "4348",
                "copy": "Have you smoked more than 12 large cigars over the last 12 months?",
                "type": "radio",
                "bundle": "smoking",
                "role": "secondary",
                "choices": {
                    "6733": {
                        "id": "6733",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6734": {
                        "id": "6734",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4349",
                "copy": "Please choose the response that best describes your smoking habits over the last 12 months.  How often have you used cigarettes cigarillos, colts, cigars, pipes, chewing tobacco, snuff, e-cigarettes, vaporizers, nicotine gum or patches or any other form of tobacco or nicotine substitute?",
                "type": "radio",
                "bundle": "smoking",
                "role": "primary",
                "choices": {
                    "6735": {
                        "id": "6735",
                        "copy": "I am a daily smoker",
                        "path": "application"
                    },
                    "6736": {
                        "id": "6736",
                        "copy": "I smoke occasionally",
                        "path": "application"
                    },
                    "6737": {
                        "id": "6737",
                        "copy": "I have not used/smoked in the last 12 months or greater",
                        "path": "application"
                    },
                    "6738": {
                        "id": "6738",
                        "copy": "I have never smoked",
                        "path": "application"
                    },
                    "6739": {
                        "id": "6739",
                        "copy": "I quit less than a year ago",
                        "path": "application"
                    }
                },
                "bullets": [
                    "Please choose the response that best describes your smoking habits over the last 12 months.  How often have you used cigarettes cigarillos",
                    " colts",
                    " cigars",
                    " pipes",
                    " chewing tobacco",
                    " snuff",
                    " e-cigarettes",
                    " vaporizers",
                    " nicotine gum or patches or any other form of tobacco or nicotine substitute"
                ]
            }
        ]
    },
    "statement": {
        "type": "special",
        "questions": [
            {
                "id": "4297",
                "copy": "Is this policy intended to replace any life insurance currently in effect?",
                "stated": "this policy is not intended to replace any life insurance currently in effect",
                "type": "radio",
                "bundle": "statement",
                "choices": {
                    "6641": {
                        "id": "6641",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6642": {
                        "id": "6642",
                        "copy": "No",
                        "path": "application"
                    }
                }
            },
            {
                "id": "4298",
                "copy": "Are you a Canadian citizen or landed immigrant, currently residing in Canada?",
                "stated": "you are a Canadian citizen or landed immigrant, currently residing in Canada",
                "type": "radio",
                "bundle": "statement",
                "choices": {
                    "6643": {
                        "id": "6643",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6644": {
                        "id": "6644",
                        "copy": "No",
                        "path": "unqualified"
                    }
                }
            },
            {
                "id": "4303",
                "copy": "Do you agree to answer all of these questions truthfully?",
                "stated": "you agree to answer all of these questions truthfully",
                "type": "radio",
                "bundle": "statement",
                "choices": {
                    "6650": {
                        "id": "6650",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6651": {
                        "id": "6651",
                        "copy": "No",
                        "path": "unqualified"
                    }
                }
            }
        ]
    },
    "occupation": {
        "type": "special",
        "questions": [
            {
                "id": "4299",
                "copy": "Does your occupation belong to any of the following?",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6645": {
                        "id": "6645",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6646": {
                        "id": "6646",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    "Fisherman (not coming home nightly)",
                    "Forestry (Chipper, Choke Setter, Chopper, Faller, Bucker, Marker, Sorter, Logger, Lumberjack, Raftsman, Riverman, Woodsman)",
                    "Journalist & Photographic journalist, Missionary (international assignments)",
                    "Building industry (Asbestos Worker, structural iron or steel workers on towers, bridges, buildings over 3 stories)",
                    "Professional Diving (Cableman, Deep Sea, demolition, oil rig, pipe laying, saturation)",
                    "Carnival, Acrobat, Animal trainer, Rodeo Performer/Competitor",
                    "Offshore workers (Oil and Gas)",
                    "Professional gambler",
                    "Professional Athlete",
                    "Military currently deployed or under orders to deploy",
                    "Commercial aviation (other than pilot for major airline)"
                ],
                "combined": "Does your occupation belong to any of the following?<ul><li>Fisherman (not coming home nightly)</li><li>Forestry (Chipper, Choke Setter, Chopper, Faller, Bucker, Marker, Sorter, Logger, Lumberjack, Raftsman, Riverman, Woodsman)</li><li>Journalist & Photographic journalist, Missionary (internationalassignments)</li><li>Building industry (Asbestos Worker, structural iron or Steel workers on towers, bridges, buildings over 3 stories)</li><li>Professional Diving (Cableman, Deep Sea, demolition, oil rig, pipe laying, saturation)</li><li>Carnival, Acrobat, Animal trainer, RodeoPerformer/Competitor</li><li>Off shore workers(Oil and Gas)</li><li>Professional gambler</li><li>Professional Athlete</li><li>Military currently deployed or under orders to deploy</li><li>Commercial aviation (other than pilot for major airline)</li></ul>"
            }
        ]
    },
    "none": {
        "type": "special",
        "questions": [
            {
                "id": "4304",
                "copy": "Are you currently restricted to a wheelchair, bedridden, hospitalized or require full time care?",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6652": {
                        "id": "6652",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6653": {
                        "id": "6653",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4352",
                "copy": "Have you consulted a physician for an illness or condition which has not yet been diagnosed or for which testing is still in progress or not yet completed, or are you awaiting the result of any clinical test?",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6749": {
                        "id": "6749",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6750": {
                        "id": "6750",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4353",
                "copy": "Have you noticed any symptoms or health problems for which you have not yet consulted a physician, such as:",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6751": {
                        "id": "6751",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6752": {
                        "id": "6752",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    " lump or mass of the breasts",
                    " shortness of breath",
                    " chest pain",
                    " unexplained weight loss",
                    " dizziness",
                    " loss of memory or balance",
                    " numbness",
                    " rectal bleeding",
                    " prostate or other problems not mentioned above"
                ]
            },
            {
                "id": "4354",
                "copy": "In the past 2 years, have you flown as a private pilot or student pilot, or have you engaged in:",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6753": {
                        "id": "6753",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6754": {
                        "id": "6754",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes",
                "bullets": [
                    " SCUBA diving deeper than 100 feet",
                    " sky diving",
                    " hang gliding",
                    " motor vehicle racing",
                    " mountain climbing",
                    " heli-skiing",
                    " back country skiing/snowboarding/snowmobiling",
                    " or extreme sports"
                ]
            },
            {
                "id": "4355",
                "copy": "Other than travel totaling 6 months or less per year within, Europe, Australia, Japan, China, Singapore or the Caribbean, do you have any plans to travel or reside outside of North America in the next 12 months?",
                "type": "radio",
                "bundle": "none",
                "choices": {
                    "6755": {
                        "id": "6755",
                        "copy": "Yes",
                        "path": "mismatch"
                    },
                    "6756": {
                        "id": "6756",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "trigger": {
        "type": "special",
        "questions": [
            {
                "id": "4322",
                "copy": "COLONOSCOPY RESULT",
                "type": "heading",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger"
            },
            {
                "id": "4323",
                "copy": "Hemorrhoids only",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6685": {
                        "id": "6685",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6686": {
                        "id": "6686",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4324",
                "copy": "IBS (Irritable Bowel Syndrome) only, controlled",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6687": {
                        "id": "6687",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6688": {
                        "id": "6688",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4325",
                "copy": "Other",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6689": {
                        "id": "6689",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6690": {
                        "id": "6690",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4326",
                "copy": "FOLLOW-UP COLONOSCOPY RECOMMENDED",
                "type": "heading",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger"
            },
            {
                "id": "4327",
                "copy": "No",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6692": {
                        "id": "6692",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6693": {
                        "id": "6693",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4328",
                "copy": "Do not know",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6694": {
                        "id": "6694",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6695": {
                        "id": "6695",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4329",
                "copy": "Yes, in 5-10 years",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6696": {
                        "id": "6696",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6697": {
                        "id": "6697",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4330",
                "copy": "Yes, in 3 years",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6698": {
                        "id": "6698",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6699": {
                        "id": "6699",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4331",
                "copy": "Yes, within 3 years",
                "type": "radio",
                "parent": "6682",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6700": {
                        "id": "6700",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6701": {
                        "id": "6701",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4338",
                "copy": "Depression was diagnosed:",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6713": {
                        "id": "6713",
                        "copy": "Within the last 12 months",
                        "path": "mismatch"
                    },
                    "6714": {
                        "id": "6714",
                        "copy": "More than 12 months ago",
                        "path": "application"
                    }
                }
            },
            {
                "id": "4339",
                "copy": "Are you being followed by:",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6715": {
                        "id": "6715",
                        "copy": "Specialist (psychiatrist)",
                        "path": "application"
                    },
                    "6716": {
                        "id": "6716",
                        "copy": "Family doctor",
                        "path": "application"
                    }
                }
            },
            {
                "id": "4340",
                "copy": "Within the last 2 years, have you required any time off work because of depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6717": {
                        "id": "6717",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6718": {
                        "id": "6718",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4341",
                "copy": "Within the last 2 years, have you been hospitalized or had emergency visit(s) because of depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6719": {
                        "id": "6719",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6720": {
                        "id": "6720",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4342",
                "copy": "Have you ever had any suicidal thoughts or attempts?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6721": {
                        "id": "6721",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6722": {
                        "id": "6722",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            },
            {
                "id": "4343",
                "copy": "Within the last year, have you had the increase in the dosage of medication for depression?",
                "type": "radio",
                "parent": "6711",
                "role": "secondary",
                "bundle": "trigger",
                "choices": {
                    "6723": {
                        "id": "6723",
                        "copy": "Yes",
                        "path": "application"
                    },
                    "6724": {
                        "id": "6724",
                        "copy": "No",
                        "path": "application"
                    }
                },
                "isYesNo": "yes"
            }
        ]
    },
    "lifestyle": {
        "type": "special",
        "questions": [
            {
                "id": "4350",
                "copy": "What is your weekly alcohol consumption?",
                "help": "One unit of alcohol consists of; 43ml (1.5 oz.) spirits, 142ml (5 oz.) of wine or 341ml (12 oz.) of beer",
                "type": "radio",
                "bundle": "none",
                "order": 1,
                "choices": {
                    "6741": {
                        "id": "6741",
                        "copy": "26 or more",
                        "path": "mismatch",
                        "order": 3
                    },
                    "6743": {
                        "id": "6743",
                        "copy": "1-25 drinks",
                        "path": "application",
                        "order": 2
                    },
                    "6744": {
                        "id": "6744",
                        "copy": "None, or less than weekly",
                        "path": "application",
                        "order": 1
                    }
                }
            },
            {
                "id": "4351",
                "copy": "What is your weekly marijuana consumption?",
                "help": "Please indicate how often you consume marijuana or other cannabis products",
                "type": "radio",
                "bundle": "none",
                "order": 2,
                "choices": {
                    "6746": {
                        "id": "6746",
                        "copy": "4 or more",
                        "path": "mismatch",
                        "order": 3
                    },
                    "6747": {
                        "id": "6747",
                        "copy": "3 or less",
                        "path": "application",
                        "order": 2
                    },
                    "6748": {
                        "id": "6748",
                        "copy": "None, or less than weekly",
                        "path": "application",
                        "order": 1
                    }
                }
            }
        ]
    }
};