import { useContext } from 'react';

import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Image,
  ProgressBar,
  Row,
} from 'react-bootstrap';

import * as config from '../config.js';
import logo from '../css/logo.png';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
} from '../libs/contexts.js';
import {
  BaseDesktopFaq,
  BaseDesktopFooter,
  BaseDesktopHeader,
  BaseDesktopMenu,
  BaseDesktopProgressBar,
  BaseDesktopSidebar,
} from './base-desktop.jsx';

const ModernDesktopBody = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className={"yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3"}>
                        <Container fluid>
                            <Row className='mt-4'>
                                <Col>
                                    {"yes" === layout.faq ?
                                        <>
                                            <ModernDesktopFaq />
                                        </>
                                        :
                                        <>
                                            {"jump" === layout.specialView ?
                                                <p>We are getting your quote ready, please hold...</p>
                                                :
                                                <>
                                                    <ModernDesktopProgressBar />
                                                    <MyOutlet></MyOutlet>
                                                </>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                    {"yes" !== layout.iframeVisible &&
                        <Col className="col-lg-4 col-md-5 col-xl-3">
                            {"jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                <>
                                    <ModernDesktopSidebar />
                                </>
                            }
                        </Col>
                    }
                </Row>
            </Container>
        </>
    );
}

export const ModernDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (<>
        <div className="content-container">
            <ModernDesktopMenu />
            <ModernDesktopBody MyOutlet={MyOutlet} />
        </div>

        {"end" !== layout.currentScreen &&
            <ModernDesktopFooter />
        }
    </>);
}

export const ModernDesktopLayout3 = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (<>
        <div className="content-container">
            <ModernDesktopMenu />
            <Container fluid>
                <Row>
                    <Col className="desktop-area">
                        <Container fluid>
                            <Row>
                                <Col className={"yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3"}>
                                    <Container fluid>
                                        <Row>
                                            {/* <Col>
                                                <ModernDesktopHeader />
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            <Col>
                                                {"yes" === layout.faq ?
                                                    <>
                                                        {/* <ModernDesktopFaq /> */}
                                                    </>
                                                    :
                                                    <>
                                                        {"jump" === layout.specialView ?
                                                            <p>We are getting your quote ready, please hold...</p>
                                                            :
                                                            <>
                                                                {"steady" === branding.progressMode &&
                                                                    <>
                                                                        {/* <ModernDesktopProgressBar /> */}
                                                                    </>
                                                                }
                                                                <MyOutlet></MyOutlet>
                                                            </>
                                                        }</>

                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                {"yes" !== layout.iframeVisible &&
                                    <Col className="col-lg-4 col-md-5 col-xl-3">
                                        {"jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                            <>
                                                {/* <ModernDesktopSidebar /> */}
                                            </>
                                        }
                                    </Col>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>

        {"end" !== layout.currentScreen &&
            <ModernDesktopFooter />
        }
    </>);
}


export const ModernDesktopLayout2 = ({ MyOutlet }) => {

    return (
        <>
            <div className="content-container">


            </div>



        </>
    )


}

export const ModernDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const ModernDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}

export const ModernDesktopHeader = () => {

    // no header content in this layout  

    return (
        <></>
    );

}

export const ModernDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);

    return (
        <Container fluid className="menu">
            <Row>
                <Col className='desktop-menu '>
                    <Container fluid>
                        <Row>
                            <Col>
                                <>
                                    <div className="broker-logo">
                                        <a target="_blank" href={branding.website} rel="noopener noreferrer">
                                            <Image className="broker-logo ml-0 ml-lg-1 f-black" src={logo} />
                                        </a>
                                    </div>
                                </>
                            </Col>
                            <Col className="text-end text-right">
                                <a className="btn btn-primary phone-button" href={layout.phoneHref ? layout.phoneHref.href : "#"}>{layout.phone}</a>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            {/* <Row className="mt-0">
                <Col className="px-0 mx-0">
                    {"jump" !== layout.specialView && !['policy', 'thankyou', 'opportunities'].includes(layout.currentScreen) &&
                        <>
                            <ModernDesktopProgressBar />
                        </>
                    }
                </Col>
            </Row> */}
        </Container>
    )
}

export const ModernDesktopProgressBar = () => {

    return (
        <BaseDesktopProgressBar />
    )

}

export const ModernDesktopSidebar = () => {

    return (
        <BaseDesktopSidebar />
    )

}
