import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    BsEmojiHeartEyes,
    BsExclamationSquare,
} from 'react-icons/bs';
import { RiCustomerServiceLine } from 'react-icons/ri';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';

export const ProductMismatch = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {

        setLayout({ currentScreen: 'opportunities' });

    }, []);

    return (
        <>
            <Card>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col className='col-md-2'>
                                <h1 style={{ fontSize: "500%" }}><BsEmojiHeartEyes /></h1>
                            </Col>
                            <Col>
                                <Card.Title>Exciting news!</Card.Title>
                                <Card.Text>
                                    There are products that are a better fit for you. Let's talk!
                                </Card.Text>
                                <Card.Text>
                                    {layout.phone} or {layout.email}
                                </Card.Text>
                            </Col>
                        </Row>
                    </Container>

                </Card.Body>
            </Card>
        </>
    );

}


export const UnqualifiedLead = () => {


    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {

        setLayout({ currentScreen: 'thankyou' });

    }, []);

    return (
        <>
            <Card>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col className='col-md-2'>
                                <h1 style={{ fontSize: "500%" }}><BsEmojiHeartEyes /></h1>
                            </Col>
                            <Col>
                                <Card.Title>Exciting news!</Card.Title>
                                <Card.Text>
                                    There are products that are a better fit for you. Let's talk!
                                </Card.Text>
                                <Card.Text>
                                    {layout.phone} or {layout.email}
                                </Card.Text>
                            </Col>
                        </Row>
                    </Container>

                </Card.Body>
            </Card>
        </>
    );

}