import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { BsCloudDownload } from 'react-icons/bs';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
  getDownloadLink,
  getMessages,
  requestApproveApplication,
  sendEmailLinkRequest,
} from '../libs/api.js';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
} from '../libs/contexts.js';
import { scrollUp } from '../libs/ui.js';
import { EmailValidationBox } from './email-code.jsx';
import {
  PolicyDelayed,
  PolicyFailed,
  PolicyOngoing,
} from './policy-progress.jsx';

export const Policy = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    let navigate = useNavigate();
    const { session, setSession } = useContext(SessionContext);
    const [timer, setTimer] = useState(false);
    const [approved, setApproved] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [verified, setVerified] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);
    const [failed, setFailed] = useState(false);
    const [emailOk, setEmailOk] = useState(false);
    const [longRunning, setLongRunning] = useState(false);


    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: true,
            currentScreen: 'policy'
        });

    }, []);

    useEffect(() => {
        if (session.approved) {
            //onsole.info("Session approval changed", session, Date.now());
            if ("yes" === session.approved) {
                //
            } else {

                /* DEBUG
                let newSessionEntry = {
                    "outcome": "stop",
                    "next": "thankyou"
                };

                if (undefined !== session.disqualified) {
                    newSessionEntry.disqualified = session.disqualified;
                } else {
                    newSessionEntry.disqualified = {};
                }

                newSessionEntry.disqualified.mib = "yes";
                setSession(newSessionEntry);
                */
                let newVerified = 'fail';
                setVerified(newVerified);
                clearInterval(timer);
                setTimer(false);
                //navigate(`/thankyou`);
            }

        }
    }, [session.approved]);



    useEffect(() => {
        if (waiting) {

            if (!session.applied) {
                let newSessionEntry = { applied: "yes" };
                setSession(newSessionEntry);
            }
        }
    }, [waiting]);

    useEffect(() => {
        if (emailOk) {
            //onsole.info("Requesting app", session.token);
            requestApproveApplication(
                {
                    token: session.token,
                    next: (data) => {

                        setInterval(() => {
                            //onsole.info("Starting to poll");
                            getMessages({
                                token: session.token,
                                next: (data) => {
                                    //onsole.info("Got message", data);
                                    setSession({ approved: "yes", applied: "yes", download: "ready" });
                                }
                            })
                        }, 3000)
                        setTimeout(() => {
                            setLongRunning(true);
                        }, 30000);
                    },
                }
            );
        }
    }, [emailOk, session.token])

    useEffect(() => {


        if (session.approved) {

            if ("yes" === session.approved) {

                setApproved("yes");
                if (session.applied) {

                    if ("yes" === session.applied) {

                        if ("ready" === session.download) {

                            getDownloadLink({
                                token: session.token,
                                next: (data) => {
                                    let newVerified = 'pass';
                                    setVerified(newVerified);
                                    let newPdfLink = data.url;
                                    setPdfLink(newPdfLink);
                                    clearInterval(timer);
                                    setTimer(false);
                                }
                            });
                        } else {
                            //onsole.info("Download not ready");
                        }
                    }
                } else {
                    //onsole.info("Not applied yet");
                    // if (emailOk) {
                    //     requestApproveApplication((data) => {
                    //         setWaiting(Date.now());
                    //     });
                    // }
                }
            } else {
                //onsole.info("Not approved yet");
                setApproved("no");
            }

        }
    }, [session.approved, session.applied, session.download, session.token]);

    return (
        <>
            <Container fluid>

                {emailOk ?
                    <>
                        {verified ?
                            <>
                                <Row>
                                    <Col>
                                        <h1 className="display-4 fw-bold lh-1 mb-3">Your insurance policy</h1>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    {'pass' === verified ?
                                        <Col className="col-lg-12 text-center text-lg-start">
                                            <div className="mt-1">
                                                {pdfLink ?
                                                    <DownloadOptions pdfLink={pdfLink} />
                                                    :

                                                    <p>Your application has been approved. We're preparing your documents...</p>


                                                }
                                            </div>
                                        </Col>
                                        :
                                        <Col className="col-lg-12 text-center text-lg-start">
                                            <PolicyDelayed />
                                        </Col>
                                    }

                                </Row>
                            </>
                            :
                            <>
                                {failed ?
                                    <Row className="mt-4">
                                        <Col>
                                            <PolicyFailed />
                                        </Col>
                                    </Row>
                                    :
                                    <>

                                        {longRunning ?
                                            <>
                                                <PolicyDelayed />
                                            </>
                                            :
                                            <>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <PolicyOngoing />
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                    </>
                                }

                            </>
                        }
                    </>
                    :
                    <>
                        <Row>
                            <Col>
                                <EmailValidationBox setEmailOk={setEmailOk} />
                            </Col>
                        </Row>
                    </>
                }

            </Container>

        </>
    )
}

const DownloadOptions = ({ pdfLink }) => {

    const { session } = useContext(SessionContext);
    const [requested, setRequested] = useState(false);

    const requestEmail = (e) => {
        setRequested(true);
        sendEmailLinkRequest((data) => {
            //onsole.info(data);
        });
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    Your policy is ready!
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Button target="_blank" href={pdfLink} variant="outline-primary"><BsCloudDownload /> Download</Button>
                    {/* {requested ?
                        <>
                            <hr />
                            <p>A copy of your insurance policy has been sent to {session.email}.</p>
                        </>
                        :
                        <>
                            {' '}
                            {'skipped' !== session.email_code &&
                                <Button onClick={requestEmail} variant="outline-primary"><MdOutlineAttachEmail /> Email</Button>
                            }
                        </>
                    } */}

                </Col>
            </Row>
        </Container>

    )

}