import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { BrandingContext } from '../libs/contexts';
import { FlatLayout } from './flat.jsx';
//import { UiLayouts } from '../libs/ui';
import { HeroLayout } from './hero.jsx';
import { JumperLayout } from './jumper.jsx';
import { ModernLayout } from './modern.jsx';

// export const UiPlaceholder = (props) => {
//     let Component = UiComponents[props.block];
//     return (<Component {...props} />);
// }

export const UiLayoutPlaceholder = (props) => {

    const { branding } = useContext(BrandingContext);
    const [pageLayout, setPageLayout] = useState(false);

    useEffect(() => {

        if (branding && branding.layout) {
            if (['hero', 'modern', 'flat'].includes(branding.layout)) {
                setPageLayout(branding.layout);
            } else {
                //onsole.info("Invalid layout name");
            }
        } else {
            //onsole.info("No layout");
        }

    }, [branding]);

    return (
        <>
            {"flat" === pageLayout &&
                <FlatLayout {...props} />

            }
            {"modern" === pageLayout &&
                <ModernLayout {...props} />

            }
            {"hero" === pageLayout &&
                <HeroLayout {...props} />
            }
            {"jumper" === pageLayout &&
                <JumperLayout {...props} />
            }
        </>
        //  <Component {...props} />

    );
}
