import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts.js';
import {
    getFields,
    qualify,
    validateScreenFields,
} from '../libs/workflow.js';
import { UiLayoutPlaceholder } from './placeholders.jsx';

export const Page = () => {

    const navigate = useNavigate();
    let location = useLocation();

    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);
    const [lastValidationUpdate, setLastValidationUpdate] = useState(false);


    useEffect(() => {

        if (layout.currentScreen) {
            if (['beneficiary', 'payment', 'consent', 'policy'].includes(layout.currentScreen) && ['unqualified', 'mismatch'].includes(layout.disqualified)) {
                let paths = {
                    unqualified: '/thankyou',
                    mismatch: '/opportunities'
                };
                navigate(paths[layout.disqualified]);
            } else {
                if (!['opportunities', 'thankyou'].includes(layout.currentScreen)) {

                    setLayout({
                        fields: getFields({ screen: layout.currentScreen })
                    });
                }
            }
        }

    }, [layout.currentScreen, layout.disqualified]);



    useEffect(() => {


        if (lastValidationUpdate) {
            /*
            setLayout({
                fields: lastValidationUpdate
            });
            */
        }

    }, [lastValidationUpdate]);

    useEffect(() => {

        /* here we intercept a jump */


        if (layout.currentScreen) {
            if (undefined !== session.jump_entry && "jump" === session.jump_entry) {
                if ("yes" === session.jumped) {
                    let mandatoryFields = ['first_name', 'last_name', 'email', 'phone'];
                    let nextPage = 'quoter';
                    for (let f of mandatoryFields) {
                        if (undefined === session[f] || "" === session[f]) {
                            nextPage = 'welcome';
                        }
                    }


                    setLayout({ specialView: "no" });
                    setSession({ jump_entry: "done" });

                    if ('welcome' !== nextPage) {
                        setLayout(
                            {
                                currentScreen: 'quoter'
                            }
                        );
                        // layout.currentScreen
                        navigate(nextPage);
                    }
                }

            }
        }




    }, [layout.currentScreen, session.jump_entry, session.jumped, session]);

    useEffect(() => {


        /* here we intercept bad navigation (such as people going directly to a specific screen) */
        let simpleUri;
        if ("/" === location.pathname) {
            simpleUri = "welcome";
        } else {
            simpleUri = location.pathname.split('/').pop();
        }

        if (simpleUri !== layout.currentScreen) {
            if (undefined === session.jump_entry && undefined === session.quote) {
                navigate("/welcome");
            }
        }

        setSession({ latest_step: layout.currentScreen });



    }, [location.pathname, layout.currentScreen, session.jump_entry, session.quote]);


    useEffect(() => {

        if (undefined !== layout.currentScreen && session.quote) {

            let validation = validateScreenFields({ screen: layout.currentScreen, session: session });

            setLayout({
                validation: validation
            });

            let allFields = [];

            if (validation && validation.valid && validation.valid.length > 0) {
                allFields = allFields.concat(validation.valid);
            }
            if (validation && validation.invalid && validation.invalid.length > 0) {
                allFields = allFields.concat(validation.invalid);
            }


            if (undefined === layout.fields || 0 === Object.keys(layout.fields).length) {
                //onsole.info("We don't have fields", layout.currentScreen, layout);
                let newFields = {};
                if (allFields) {
                    for (let field of allFields) {
                        newFields[field] = "unknown";
                    }
                }
                setLayout(
                    { fields: newFields }
                );
            } else {
                //onsole.info("We have some fields", layout.fields);
                let newFields = {};
                let missing = false;
                let errors = false;
                for (let field of Object.keys(layout.fields)) {
                    let found = false;
                    if (validation.invalid && validation.invalid.includes(field)) {

                        if (layout.lastValidated && layout.lastValidated === layout.currentScreen) {
                            newFields[field] = "error";
                        } else {
                            newFields[field] = "unknown";
                        }

                        found = true;
                        errors = true;
                    } else {
                        if (validation.valid && validation.valid.includes(field)) {
                            newFields[field] = "ok";
                            found = true;
                        }
                    }
                    if (!found) {
                        missing = true;
                        //onsole.info("Missing", field);
                    }
                }

                if (JSON.stringify(layout.fields) !== JSON.stringify(newFields)) {
                    //todo check if we can remove this entirely                    
                    setLastValidationUpdate(newFields);
                }



                if (layout.fields && Object.keys(layout.fields).length > 0 && !missing) {
                    //onsole.info("New val", layout.lastValidated, errors);
                    if (errors) {
                        if (layout.lastValidated && layout.lastValidated === layout.currentScreen) {
                            setLayout({
                                screenReady: !errors
                            });
                        }
                    } else {
                        setLayout({
                            screenReady: !errors
                        });
                        if (!errors) {
                            setLayout({
                                lastValidated: layout.currentScreen
                            });
                        }
                    }
                } else {
                    //onsole.info("Cannot validate", layout.fields, missing);
                }

            }

            let qualification = qualify({ session: session });
            if (qualification && qualification.disqualified) {
                setLayout({ disqualified: qualification.unhappyPath });
            } else {
                setLayout({ disqualified: false });
            }
            //onsole.info("Qualification", qualification);

        }

    }, [layout.currentScreen, layout.fields, layout.lastValidated, session]);



    return (
        <>
            {layout.name &&
                <UiLayoutPlaceholder MyOutlet={Outlet} layoutName={layout.name} />
            }
        </>
    )

}

